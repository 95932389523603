import Intercom from '@intercom/messenger-js-sdk';

export const initializeIntercom = (user, domainRouteName) => {
    if (!import.meta.env.VITE_INTERCOM_APP_ID) return;
    if (!user) return;
    if (domainRouteName !== 'host-marketplace') return;

    const isDesktop = window.innerWidth >= 1024;
    if (!isDesktop) {
        return;
    }

    Intercom({
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        user_id: user.id,
        name: user.name,
        email: user.email,
        created_at: Math.floor(new Date(user.created_at).getTime() / 1000),
        user_hash: user.intercomHash,
    });
};

export default Intercom;
